<template>
  <div class="main-material-consumption">
    <zy-title>
      主材消耗主要物料
      <template slot="more">
        <zy-select
          :data="typeList"
          @selectType="changeType"
          v-model="currentSelect"
        >
          材料类别
        </zy-select>
      </template>
    </zy-title>
    <div class="main-material-consumption-content">
      <div ref="echart"></div>
    </div>
  </div>
</template>

<script>
import components from "@/jxyh/components/index.js"
import util from "../util"
import * as echarts from "echarts"
export default {
  ...components(["ZyTitle", "ZySelect"]),
  data() {
    return {
      currentSelect: 0,
      title: [],
      values: [],
      chartDom: null,
      info: {},
    }
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {
          pmsConsumptionVOList: [],
        }
      },
    },
  },
  watch: {
    model: {
      deep: true,
      immediate: true,
      handler(val) {
        this.initData()
      },
    },
  },
  computed: {
    typeList() {
      return util.findTypeList(this.model.pmsConsumptionVOList, "materialName")
    },
    option() {
      return {
        title: {
          text: this.info.unitName || "",
          textStyle: {
            color: "#ffffff",
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        textStyle: {
          fontFamily: "SourceHanSansCN",
        },
        tooltip: {
          trigger: "axis",
          borderWidth: 1,
          backgroundColor: "rgba(30,80,135,0.95)",
          borderColor: "#33D8FA",
          textStyle: {
            fontFamily: "SourceHanSansCN",
            fontWeight: 400,
            color: "#ffffff",
            fontSize: 14,
          },
        },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "0%",
          top: 50,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: this.titles,
          axisLabel: {
            textStyle: {
              color: "#ffffff",
              fontSize: 12,
            },
          },
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,1)",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              color: "#ffffff",
              fontSize: 14,
              fontWeight: "bold",
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed", //虚线
              color: "rgba(255,255,255,0.1)",
            },
            show: true, //隐藏
          },
        },
        series: [
          {
            type: "bar",
            barMaxWidth: "50px",
            itemStyle: {
              normal: {
                color: "#0360F5",
              },
            },
            label: {
              normal: {
                show: true,
                position: "top",
                textStyle: {
                  color: "#ffffff", // 标签文字颜色
                  fontSize: 12, // 标签文字大小
                  fontWeight: "500",
                },
              },
            },
            emphasis: {
              focus: "series",
            },
            data: this.values,
          },
        ],
        //动画延迟1s
        animationDelay: 1000,
      }
    },
  },

  methods: {
    updateChart() {
      this.$nextTick(() => {
        if (!this.chartDom) {
          const ref = this.$refs.echart
          this.chartDom = echarts.init(ref)
        }
        this.chartDom.setOption(this.option)
      })
    },
    changeType(val) {
      this.currentSelect = val
      this.initData()
    },
    initData() {
      const obj = this.typeList[this.currentSelect] || { value: [] }
      this.info = obj.value
      const list = this.info.list || []
      const titles = []
      const values = []
      for (let i = 0; i < list.length; i++) {
        const item = list[i]
        const title = item.wl
        const value = item.ckcb
        titles.push(title)
        values.push(value)
      }
      this.titles = titles
      this.values = values
      this.updateChart()
    },
  },
}
</script>

<style lang="less" scoped>
.main-material-consumption {
  &-content {
    height: 270px;
    width: 100%;
    & > div {
      width: 100%;
      height: 100%;
    }
  }
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
