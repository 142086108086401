<template>
  <div class="inventory">
    <zy-title>
      当前库存总量
      <template slot="more">
        <zy-select
          :data="typeList"
          @selectType="changeType"
          v-model="currentSelect"
        >
          材料类别
        </zy-select>
      </template>
    </zy-title>
    <div class="inventory-content">
      <div class="row">
        <img
          class="inventory-content-icon"
          src="../../../assets/icons/icon_material_kczl.webp"
        />
        <div class="column inventory-content-title">
          <div>{{ info.materialName }}</div>
          <div>库存总量</div>
        </div>
      </div>
      <div
        class="row"
        v-if="info"
      >
        <div class="inventory-content-value">
          <count-to
            :endVal="info.assCurQuanBalance || 0"
            :decimals="2"
          ></count-to>
        </div>
        <div class="inventory-content-unit">{{ info.unitName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import components from "@/jxyh/components/index.js"
import CountTo from "@/components/count-to.vue"
import util from "../util"
export default {
  ...components(["ZyTitle", "ZySelect"], { CountTo }),
  data() {
    return {
      currentSelect: 0,
      info: {
        assCurQuanBalance: 0,
      },
    }
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {
          pmsTotalInventoryVOList: [],
        }
      },
    },
  },
  watch: {
    model: {
      deep: true,
      immediate: true,
      handler(val) {
        this.initData()
      },
    },
  },
  computed: {
    typeList() {
      return util.findTypeList(this.model.pmsTotalInventoryVOList, "materialName")
    },
  },
  methods: {
    changeType(val) {
      this.currentSelect = val
      this.initData()
    },
    initData() {
      const obj = this.typeList[this.currentSelect] || { value: { assCurQuanBalance: 0 } }
      this.info = obj.value
    },
  },
}
</script>

<style lang="less" scoped>
.inventory {
  &-content {
    background: linear-gradient(to right, rgba(51, 216, 250, 0.11), rgba(24, 123, 205, 0));
    width: 100%;
    height: 123px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 0 30px;
    margin-top: 10px;
    &-icon {
      width: 60px;
      height: 66px;
    }
    &-title {
      margin-left: 37px;
      & > div:nth-child(1) {
        font-weight: bold;
        font-size: 20px;
        color: #ffffff;
      }
      & > div:nth-child(2) {
        font-size: 16px;
        color: #66bbf9;
        margin-top: 12px;
      }
    }
    &-value {
      font-weight: bold;
      font-size: 32px;
      color: #ffffff;
      line-height: 41px;
    }
    &-unit {
      font-size: 16px;
      color: #ffffff;
      line-height: 24px;
      margin-left: 8px;
    }
  }
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.column {
  display: flex;
  flex-direction: column;
}
</style>
