<template>
  <zy-scroll-table
    :data="list"
    @clickRow="clickRow"
    :height="239"
  >
    <!-- 使用@clickRow必须写slot="suspension"，因为点击事件是由它发出的 -->
    <zy-scroll-table-column
      label="进场日期"
      width="220px"
      align="center"
      prop="grossTime"
    ></zy-scroll-table-column>
    <zy-scroll-table-column
      label="供应商"
      width="240px"
      prop="forwardingUnit"
    ></zy-scroll-table-column>
    <zy-scroll-table-column
      label="车辆信息"
      prop="carCode"
    ></zy-scroll-table-column>
    <zy-scroll-table-column
      label="材料型号"
      prop="materialModel"
    ></zy-scroll-table-column>
    <zy-scroll-table-column
      label="毛重(kg)"
      prop="grossWeightStr"
      align="center"
    ></zy-scroll-table-column>
    <zy-scroll-table-column
      label="皮重(kg)"
      prop="tareWeightStr"
      align="center"
    ></zy-scroll-table-column>
    <zy-scroll-table-column
      label="净重(kg)"
      prop="netWeightStr"
      align="center"
    ></zy-scroll-table-column>
    <zy-scroll-table-column
      label="出场日期"
      prop="tareTime"
      align="center"
      width="220px"
    ></zy-scroll-table-column>
    <template
      slot="suspension"
      slot-scope="{ index }"
    >
      <div
        v-if="selectRow == index"
        class="records-row-bg"
      >
        <img src="../../../assets/icons/icon_record_arrow.png" />
        <div></div>
      </div>
    </template>
  </zy-scroll-table>
</template>

<script>
import { specialInspections } from "@/jxyh/api/material"
import components from "@/jxyh/components"
export default {
  ...components(["ZyScrollTable", "ZyScrollTableColumn"]),
  data() {
    return {
      selectRow: 0,
      list: [],
    }
  },
  created() {
    this.$nextTick(() => {
      this.getSpecialInspections()
    })
  },
  methods: {
    clickRow(row, index) {
      this.selectRow = index
      const obj = this.$system.deepClone(row)
      this.$emit("select", obj, index)
    },
    getSpecialInspections() {
      specialInspections()
        .then((res) => {
          let list = res.data || []
          if (list.length > 100) {
            list = list.slice(0, 99)
          }
          if (list.length) {
            this.clickRow(list[0], 0)
          }
          this.list = list
        })
        .catch((_) => {})
    },
  },
}
</script>

<style lang="less" scoped>
.records-row-bg {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  & > img {
    width: 11px;
    height: 100%;
  }
  & > div {
    background: linear-gradient(180deg, rgba(28, 47, 99, 0) 0%, rgba(0, 233, 255, 0.2) 100%);
    border: 1px solid #33d8fa;
    border-left: unset;
    width: 100%;
    height: 100%;
  }
}
</style>
