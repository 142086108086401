export default {
  findTypeList: (list, key) => {
    list = list || []
    const typeList = []
    for (let i = 0; i < list.length; i++) {
      const obj = list[i]
      const label = obj[key]
      const id = `${i}`
      const item = {
        label,
        id,
        value: obj,
      }
      typeList.push(item)
    }
    return typeList
  },
}
