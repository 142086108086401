<template>
  <div class="material">
    <div class="material-center">
      <zy-layout>
        <zy-layout-column animation="left">
          <!-- 当前库存总量 -->
          <inventory :model="model"></inventory>
          <!-- 总量对比 -->
          <contrast :model="model"></contrast>
          <!-- 消耗Top10 -->
          <ranking :model="model"></ranking>
        </zy-layout-column>
        <zy-layout-column animation="right">
          <!-- 主材消耗主要物料 -->
          <main-material-consumption :model="model"></main-material-consumption>
          <!-- 主材累计消耗 -->
          <cumulative :model="model"></cumulative>
        </zy-layout-column>
      </zy-layout>
    </div>
    <div class="material-bottom">
      <zy-layout>
        <zy-layout-column animation="left">
          <zy-title>材料进出场记录</zy-title>
          <record-image :record="selectRecord"></record-image>
        </zy-layout-column>
        <zy-layout-column animation="right">
          <entry-and-exit-records
            style="margin-top: 44px"
            @select="selectRecords"
          ></entry-and-exit-records>
        </zy-layout-column>
      </zy-layout>
    </div>
  </div>
</template>

<script>
import components from "@/jxyh/components/index.js"
import EntryAndExitRecords from "./components/entryAndExitRecords.vue"
import RecordImage from "./components/recordImage.vue"
import Inventory from "./components/inventory.vue"
import Contrast from "./components/contrast.vue"
import Ranking from "./components/ranking.vue"
import { material } from "@/jxyh/api/material"
import Cumulative from "./components/cumulative.vue"
import MainMaterialConsumption from "./components/mainMaterialConsumption.vue"
export default {
  ...components(["ZyLayout", "ZyLayoutColumn", "ZyTitle"], { EntryAndExitRecords, RecordImage, Inventory, Contrast, Ranking, Cumulative, MainMaterialConsumption }),
  data() {
    return {
      selectRecord: null,
      model: {},
    }
  },
  mounted() {
    this.getMaterial()
  },
  methods: {
    selectRecords(row) {
      this.selectRecord = row
    },
    getMaterial() {
      material()
        .then((res) => {
          const data = res.data || {}
          //增加类型，要求后端按照这个格式返回数据
          data.pmsConsumptionVOList = [
            {
              materialName: "混凝土",
              unitName: "立方米",
              list: data.pmsConsumptionVOList,
            },
          ]
          this.model = data
        })
        .catch((_) => {})
    },
  },
}
</script>

<style lang="less" scoped>
.material {
  display: flex;
  flex-direction: column;
  &-center {
    width: 100%;
    height: 612px;
  }
  &-bottom {
    width: 100%;
    height: 300px;
  }
}
</style>
