<template>
  <div :class="['record-image', record ? 'record-image-hl' : '']">
    <div
      class="record-image-row"
      v-for="(row, index) in list"
      :key="`row_${index}`"
    >
      <div
        class="record-image-row-img"
        v-for="(src, src_index) in row"
        :key="`${index}_${src_index}`"
      >
        <zy-image
          v-if="src && src.length"
          :src="src"
          preview
        />
        <div v-else>暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import components from "@/jxyh/components"
export default {
  ...components(["ZyImage"]),
  props: {
    record: Object,
  },
  computed: {
    list() {
      return [
        [this.record?.grossCarImage, this.record?.grossImage1],
        [this.record?.grossImage2, this.record?.grossImage3],
      ]
    },
  },
}
</script>

<style lang="less" scoped>
.record-image {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  &-hl {
    background: linear-gradient(180deg, rgba(28, 47, 99, 0) 0%, rgba(0, 233, 255, 0.46) 100%);
    border: 1px solid #33d8fa;
  }
  &-row {
    display: flex;
    flex-direction: row;
    height: 127px;
    &-img {
      width: 100%;
      height: 100%;
      background: #0f1e3f;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #ffffff;
      line-height: 21px;
      overflow: hidden;
      & > .zy-image {
        width: 100%;
        height: 100%;
      }
      & + & {
        margin-left: 10px;
      }
    }
    & + & {
      margin-top: 10px;
    }
  }
}
</style>
