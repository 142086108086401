<template>
  <div class="contrast jxyh-margin">
    <zy-title>
      总量对比
      <template slot="more">
        <zy-select
          :data="typeList"
          @selectType="changeType"
          v-model="currentSelect"
        >
          材料类别
        </zy-select>
      </template>
    </zy-title>
    <div
      class="contrast-content"
      v-if="info"
    >
      <div class="contrast-content-card">
        <img src="../../../assets/icons/icon_material_ystg.webp" />
        <div class="column">
          <div class="row">
            <div class="contrast-content-card-value">
              <count-to
                :end-val="info.totalMobilization"
                :decimals="2"
              ></count-to>
            </div>
            <!-- <div class="contrast-content-card-unit"></div> -->
          </div>
          <div
            class="contrast-content-card-title"
            style="color: #66bbf9"
          >
            进场总量
          </div>
        </div>
      </div>
      <div class="contrast-content-card">
        <img src="../../../assets/icons/icon_material_kctg.webp" />
        <div class="column">
          <div class="row">
            <div class="contrast-content-card-value">
              <count-to
                :end-val="info.totalConsume"
                :decimals="2"
              ></count-to>
            </div>
            <!-- <div class="contrast-content-card-unit"></div> -->
          </div>
          <div class="contrast-content-card-title">消耗总量</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import components from "@/jxyh/components/index.js"
import CountTo from "@/components/count-to.vue"
import util from "../util"
export default {
  ...components(["ZyTitle", "ZySelect"], { CountTo }),
  data() {
    return {
      currentSelect: 0,
      info: {},
    }
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {
          pmsTotalInventoryVOList: [],
        }
      },
    },
  },
  watch: {
    model: {
      deep: true,
      immediate: true,
      handler(val) {
        this.initData()
      },
    },
  },
  computed: {
    typeList() {
      return util.findTypeList(this.model.pmsTotalContrastVOList, "materialName")
    },
  },
  methods: {
    changeType(val) {
      this.currentSelect = val
      this.initData()
    },
    initData() {
      const obj = this.typeList[this.currentSelect] || {}
      this.info = this.$system.deepClone(obj.value)
    },
  },
}
</script>

<style lang="less" scoped>
.contrast {
  &-content {
    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    &-card {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex: 1;
      & > img {
        width: 64px;
        height: 64px;
        margin-right: 20px;
      }
      &-value {
        font-weight: bold;
        font-size: 26px;
        color: #ffffff;
        line-height: 33px;
      }
      &-unit {
        font-size: 14px;
        color: #ffffff;
        line-height: 21px;
      }
      &-title {
        font-size: 14px;
        color: #ffb822;
        line-height: 21px;
      }
    }
  }
}
.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
</style>
