<template>
  <div class="cumulative jxyh-margin">
    <zy-title>主材累计消耗</zy-title>
    <div class="cumulative-content">
      <div
        class="row cumulative-content-row"
        v-for="(row, row_index) in list"
        :key="`row_${row_index}`"
      >
        <div class="cumulative-content-row-head">
          <img
            class="icon-img"
            :src="row.icon"
          />
          <div>{{ row.title }}</div>
        </div>
        <div class="cumulative-content-row-items">
          <div
            v-for="(item, item_index) in row.children"
            :key="`item_${item_index}`"
            class="cumulative-content-row-items-item"
            :style="{
              borderLeft: `4px solid ${row.color}`,
              background: `linear-gradient(to right,${row.colors[0]} 0%,${row.colors[1]} 100%)`,
            }"
          >
            <img
              class="icon-img"
              :src="item.icon"
            />
            <div class="column">
              <div class="cumulative-content-row-items-item-value">
                <count-to
                  :endVal="Number(item.value)"
                  :decimals="2"
                ></count-to>
              </div>
              <div
                class="cumulative-content-row-items-item-title"
                :style="{ color: row.color }"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import components from "@/jxyh/components/index.js"
import CountTo from "@/components/count-to.vue"
export default {
  ...components(["ZyTitle"], { CountTo }),
  computed: {
    info() {
      if (!this.model) return {}
      return this.model?.pmsMcmrVO || {}
    },
    list() {
      if (!this.info) return []
      return [
        {
          title: "计划用量",
          icon: require("@/jxyh/assets/icons/icon_material_cumulative_jhyl.webp"),
          colors: ["rgba(0,233,255,0.2)", "rgba(28,47,99,0)"],
          color: "#66BBF9",
          children: [
            {
              title: "混凝土(m³)",
              value: this.info.planHnt || 0,
              icon: require("@/jxyh/assets/icons/icon_material_cumulative_hnt_1.webp"),
            },
            {
              title: "钢筋(t)",
              value: this.info.planGj || 0,
              icon: require("@/jxyh/assets/icons/icon_material_cumulative_gj_1.webp"),
            },
            {
              title: "砌块(m³)",
              value: this.info.planQk || 0,
              icon: require("@/jxyh/assets/icons/icon_material_cumulative_qk_1.webp"),
            },
          ],
        },
        {
          title: "实际用量",
          icon: require("@/jxyh/assets/icons/icon_material_cumulative_sjyl.webp"),
          colors: ["rgba(255,184,34,0.2)", "rgba(255,184,34,0)"],
          color: "rgba(255,184,34,1)",
          children: [
            {
              title: "混凝土(m³)",
              value: this.info.realityHnt || 0,
              icon: require("@/jxyh/assets/icons/icon_material_cumulative_hnt_2.webp"),
            },
            {
              title: "钢筋(t)",
              value: this.info.realityGj || 0,
              icon: require("@/jxyh/assets/icons/icon_material_cumulative_gj_2.webp"),
            },
            {
              title: "砌块(m³)",
              value: this.info.realityQk || 0,
              icon: require("@/jxyh/assets/icons/icon_material_cumulative_qk_2.webp"),
            },
          ],
        },
      ]
    },
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {
          pmsMcmrVO: {},
        }
      },
    },
  },
}
</script>

<style lang="less" scoped>
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.column {
  display: flex;
  flex-direction: column;
}
.icon-img {
  width: 64px;
  height: 64px;
  margin-right: 20px;
}
.cumulative {
  &-content {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    &-row {
      &-head {
        display: flex;
        align-items: center;
        width: 309px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding: 10px;
        font-weight: bold;
        font-size: 20px;
        color: #ffffff;
        line-height: 30px;
      }
      &-items {
        .row;
        width: 100%;
        margin-left: 40px;
        &-item {
          .row;
          padding: 10px;
          width: 100%;
          flex: 1;
          & + & {
            margin-left: 20px;
          }
          &-value {
            font-weight: bold;
            font-size: 26px;
            color: #ffffff;
          }
          &-title {
            font-size: 14px;
            margin-top: 11px;
          }
        }
      }
    }
    &-row + &-row {
      margin-top: 28px;
    }
  }
}
</style>
