<template>
  <div class="ranking jxyh-margin">
    <zy-title>消耗Top10</zy-title>
    <zy-scroll-table
      style="margin-top: 10px"
      :data="model.pmsConsumptionRankingVOList"
    >
      <zy-scroll-table-column
        label="排名"
        type="index"
      ></zy-scroll-table-column>
      <zy-scroll-table-column
        label="物料名称"
        prop="cbkm"
        width="100%"
        align="center"
      ></zy-scroll-table-column>
      <zy-scroll-table-column
        label="消耗(万元)"
        prop="ckcb"
        width="100%"
        align="center"
      ></zy-scroll-table-column>
    </zy-scroll-table>
  </div>
</template>

<script>
import components from "@/jxyh/components/index.js"
export default {
  ...components(["ZyTitle", "ZyScrollTable", "ZyScrollTableColumn"]),
  props: {
    model: {
      type: Object,
      default: () => {
        return {
          pmsConsumptionRankingVO: [],
        }
      },
    },
  },
}
</script>

<style lang="less" scoped>
.ranking {
}
</style>
