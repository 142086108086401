import { get } from "@/utils/request"
export function specialInspections() {
  //有些没办法的需求，只能这样切换了
  const requestProjectId = require("@/utils/requestProjectId")
  const projectId = requestProjectId.changeProject("39941432740131840", "47261066628936704")
  return get("/pms/view/material/specialInspections", { projectId })
}
export function material() {
  return get("/pms/material")
}
